<template>
  <v-row class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0">
    <v-col cols="12" class="d-flex justify-end align-center pt-0 px-1">
      <div
        v-text="nuevo ? 'Alta de vendedor' : `Vendedor: ${vendedor_original.id} - ${vendedor_original.nombre}`"
        style="font-size: 20px"
      ></div>
      <v-spacer></v-spacer>
      <v-expand-x-transition>
        <v-switch
          v-if="!nuevo && existe"
          v-model="editar"
          label="Editar"
          class="d-flex align-center py-0 my-0"
          hide-details
          dense
        ></v-switch>
      </v-expand-x-transition>
    </v-col>
    <v-card>
      <v-col cols="12">
        <v-form ref="form">
          <v-row class="pt-4">
            <template v-if="!nuevo">
              <v-col cols="6" sm="3" md="2" class="py-0">
                <v-text-field
                  v-model.trim="vendedor.id"
                  label="Código"
                  readonly
                  outlined
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" class="py-0">
                <v-text-field
                  v-model.trim="vendedor.usuario"
                  label="Usuario"
                  readonly
                  outlined
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="2" class="py-0">
                <v-text-field
                  v-model.trim="vendedor.alta"
                  label="Alta"
                  readonly
                  outlined
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-slide-x-transition>
                <v-col v-if="vendedor.inhabilitado && vendedor.baja" cols="6" sm="3" md="2" class="py-0">
                  <v-text-field
                    v-model.trim="vendedor.baja"
                    label="Baja"
                    readonly
                    outlined
                    filled
                    dense
                  ></v-text-field>
                </v-col>
              </v-slide-x-transition>
              <v-col cols="6" sm="3" class="py-0">
                <v-switch
                  v-model="vendedor.inhabilitado"
                  label="Inhabilitado"
                  class="my-0"
                  :readonly="!editar"
                  :true-value="1"
                  :false-value="0"
                  dense
                  @click="editar ? set_baja() : ''"
                ></v-switch>
              </v-col>
            </template>
            <v-col cols="12" sm="3" class="py-0">
              <v-autocomplete
                v-model="vendedor.empresa"
                label="Empresa"
                item-text="nombre_corto"
                item-value="id"
                tabindex="1"
                :rules="editar ? [rules.required, rules.notdef] : []"
                :items="$store.state.empresas"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                @change="filtrar = true"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" class="py-0">
              <v-autocomplete
                v-model="vendedor.sucursal"
                label="Sucursal"
                item-text="nombre"
                item-value="id"
                tabindex="1"
                :rules="editar ? [rules.required, rules.notdef] : []"
                :items="$store.state.sucursales"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                @change="filtrar = true"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" class="py-0">
              <v-autocomplete
                v-model="vendedor.categoria"
                label="Categoria"
                item-text="nombre"
                item-value="id"
                tabindex="1"
                :rules="editar ? [rules.required] : []"
                :items="categorias"
                :readonly="!nuevo"
                :filled="!nuevo"
                validate-on-blur
                outlined
                dense
                @change="vendedor.canal = categorias.find(cat => cat.id == vendedor.categoria).canal; filtrar = true"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" md="4" class="py-0">
              <v-text-field
                v-model.trim="vendedor.nombre"
                label="Nombre"
                tabindex="1"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field
                v-model.trim="vendedor.cuil"
                v-mask="'##-########-#'"
                label="CUIL"
                tabindex="1"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                @blur="autocomplete_dni"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-autocomplete
                v-model="vendedor.tipo_doc"
                label="Tipo documento"
                item-text="nombre"
                item-value="id"
                tabindex="1"
                :rules="editar ? [rules.required] : []"
                :items="tipos_doc"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field
                v-model.trim="vendedor.doc"
                label="Nº Documento"
                type="number"
                readonly
                outlined
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field
                v-model.trim="vendedor.objetivo"
                label="Dedic. horaria"
                type="number"
                suffix="%"
                tabindex="1"
                :rules="editar ? [rules.required, rules.range] : []"
                :style="$vuetify.breakpoint.mdAndUp ? 'width: 100px' : ''"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="4" class="py-0">
              <v-text-field
                v-model.trim="vendedor.domicilio"
                label="Domicilio"
                tabindex="1"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field
                v-model.trim="vendedor.cp"
                ref="codp"
                label="CP"
                type="number"
                tabindex="1"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                @blur="find_localidad"
              >
                <template v-slot:append-outer>
                  <v-icon
                    class="mt-1"
                    color="info"
                    title="Buscar localidad"
                    :disabled="!editar"
                    small
                    @click="dialog_buscar = true"
                  >
                    fas fa-search
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="4" class="py-0">
              <v-text-field
                v-model.trim="vendedor.localidad"
                label="Localidad"
                readonly
                outlined
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="4" class="py-0">
              <v-text-field
                v-model.trim="vendedor.email"
                ref="emailinp"
                label="Email"
                tabindex="1"
                :rules="editar ? [rules.required, rules.email] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field
                v-model.trim="vendedor.telefono"
                label="Teléfono"
                tabindex="1"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field
                v-model.trim="vendedor.fecha_nacimiento"
                v-mask="'##/##/####'"
                label="Fecha nacimiento"
                tabindex="1"
                placeholder="dd/mm/aaaa"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                @blur="valdiarFecha()"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-card>

    <v-col cols="12">
      <PtoVta
        v-if="load_component"
        :disabled="!editar"
        :vendedor="vendedor.id"
        :sucursal="vendedor.sucursal"
        :empresa="vendedor.empresa"
        :canal="vendedor.canal"
        :ptovta_v.sync="ptosvta"
        :ptovta_copy.sync="ptosvta_original"
        :copy_active="!editar"
        :nuevo="nuevo"
        :filtrar.sync="filtrar"
      />
    </v-col>

    <v-col cols="12" class="d-flex justify-end">
      <v-btn
        :color="editar ? 'error' : 'secondary'"
        @click="cerrar()"
      >
        {{ editar ? 'Cancelar' : 'Volver' }}
      </v-btn>
      <BtnConfirmar
        v-if="editar"
        clase="ml-3"
        @action="guardar"
      />
    </v-col>

    <v-dialog
      v-model="dialog"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-text>
          <div class="body-1 pt-3 pb-1">
            Existen diferentes localidades con el mismo CP ingresado, por favor elija una:
          </div>
          <div
            v-for="(item, index) in localidades"
            :key="index"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                :class="hover ? 'primary white--text body-1' : ''"
                class="py-2 my-2 text-center"
                style="cursor: pointer"
                @click="select_localidad(item)"
              >
                {{ item.nombre }}
              </v-card>
            </v-hover>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ModalLocalidades
      v-model="dialog_buscar"
      :loc.sync="vendedor.localidad"
      :cl.sync="vendedor.cod_loc"
      :cp.sync="vendedor.cp"
    />
  </v-row>
</template>

<script>
import moment from 'moment'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import PtoVta from '../../components/vendedores/PtoVta'
import ModalLocalidades from '../../components/util/ModalLocalidades'
import { parseDate } from '../../util/utils'

export default {
  data () {
    return {
      id: this.$route.params.id,
      dialog: false,
      dialog_buscar: false,
      load_component: false,
      editar: false,
      nuevo: false,
      existe: false, // variable para controllar el boton guardar en caso que no encuentre un vendedor
      filtrar: false,
      localidades: [],
      categorias: [],
      tipos_doc: [],
      ptosvta: [],
      ptosvta_original: [],
      vendedor_original: {},
      vendedor: {
        id: null,
        usuario: null,
        sucursal: -1,
        empresa: -1,
        categoria: null,
        objetivo: null,
        alta: null,
        baja: null,
        inhabilitado: null,
        nombre: null,
        domicilio: null,
        cod_loc: null,
        cp: null,
        localidad: null,
        telefono: null,
        tipo_doc: 2,
        doc: null,
        cuil: '',
        email: null,
        fecha_nacimiento: null,
        canal: null
      },
      rules: {
        required: value => !!value || 'Campo requerido',
        notdef: value => value !== -1 || 'Campo requerido',
        range: value => (value >= 0 && value <= 100) || 'Rango de 0 a 100',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Email no válido'
        },
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('vendedores/get_locales_user')
    this.$store.state.loading = false
    this.get_cat_doc()
    if (this.id) {
      this.get_data()
    } else {
      if (this.$store.state.empresas.length === 1) {
        this.vendedor.empresa = this.$store.state.empresas[0].id
      }
      if (this.$store.state.sucursales.length === 1) {
        this.vendedor.sucursal = this.$store.state.sucursales[0].id
      }
      this.editar = true
      this.nuevo = true
      this.load_component = true
    }
  },
  components: {
    BtnConfirmar,
    PtoVta,
    ModalLocalidades,
  },
  watch: {
    editar (val) {
      if (val) {
        this.$refs.form.resetValidation()
      } else {
        this.vendedor = Object.assign({}, this.vendedor_original)
      }
    }
  },
  methods: {
    valdiarFecha () {
      if (!(this.vendedor.fecha_nacimiento.length == 10 && moment(parseDate(this.vendedor.fecha_nacimiento)).isValid())) {
        this.vendedor.fecha_nacimiento = null
        this.$store.dispatch('show_snackbar', {
          text: 'Fecha inválida',
          color: 'orange'
        })
      }
    },
    async guardar () {
      if (this.$refs.form.validate()) {
        this.$store.state.loading = true

        // format data
        let data = await JSON.parse(JSON.stringify(this.vendedor))
        data.cuil = parseInt(data.cuil.replaceAll('-', ''))
        data.doc = parseInt(data.doc)
        data.objetivo = parseInt(data.objetivo)
        data.localidad = data.cod_loc
        data.tipo_doc = this.tipos_doc.find(doc => doc.id === data.tipo_doc).nombre
        delete data.usuario
        delete data.alta
        delete data.baja
        delete data.cod_loc
        delete data.cp
        delete data.id
        delete data.inhabilitado

        // format data locales
        let locales = await JSON.parse(JSON.stringify(this.ptosvta))
        locales.forEach(local => {
          delete local.empresa
          delete local.local_accesorios
          delete local.nombre
          delete local.sucursal
          if (!local.favorito) {
            local.favorito = null
          }
        })

        if (this.nuevo) {
          await this.$store.dispatch('vendedores/create_vendedor', data)
          .then(async (res) => {

            await this.$store.dispatch('vendedores/asignar_ptosvta', { vendedor: res.codigo, locales: locales })
              .then(() => {
                this.actualizar_datos()

                this.editar = false
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success',
                })
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error',
                })
              })

          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })

        } else {
          data.vendedor = parseInt(this.id)

          const edito_data = JSON.stringify(this.vendedor_original) !== JSON.stringify(this.vendedor)
          const edito_local = JSON.stringify(this.ptosvta_original) !== JSON.stringify(this.ptosvta)

          if (edito_data) {
            
            const vend_inhab_original = JSON.stringify(this.vendedor_original.inhabilitado)
            const vend_inhab = JSON.stringify(this.vendedor.inhabilitado)

            // verifica si cambio el switch de inhabilitado
            if (vend_inhab_original !== vend_inhab) {
              if (vend_inhab == 1) {
                await this.$store.dispatch('vendedores/inhabilitar_vendedor', { vendedor: data.vendedor })
                  .then(() => {
                    // vendedor inhabilitado
                  })
                  .catch(error => {
                    this.$store.dispatch('show_snackbar', {
                      text: error.message,
                      color: 'error',
                    })
                  })
              } else if (vend_inhab == 0) {
                await this.$store.dispatch('vendedores/habilitar_vendedor', { vendedor: data.vendedor })
                  .then(() => {
                    // vendedor habilitado
                  })
                  .catch(error => {
                    this.$store.dispatch('show_snackbar', {
                      text: error.message,
                      color: 'error',
                    })
                  })
              }
            }

            await this.$store.dispatch('vendedores/update_vendedor', data)
              .then(async (res) => {
                this.vendedor_original = Object.assign({}, this.vendedor)

                if (!edito_local) {
                  this.editar = false
                  this.$store.dispatch('show_snackbar', {
                    text: res.message,
                    color: 'success',
                  })
                }
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error',
                })
              })
          }

          if (edito_local) {
            await this.$store.dispatch('vendedores/asignar_ptosvta', { vendedor: this.id, locales: locales })
              .then(async (res) => {
                this.ptosvta_original = await JSON.parse(JSON.stringify(this.ptosvta))

                if (!edito_data) {
                  this.editar = false
                  this.$store.dispatch('show_snackbar', {
                    text: res.message,
                    color: 'success',
                  })
                }
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error',
                })
              })
          }

          if (edito_data && edito_local) {
            this.editar = false
            this.$store.dispatch('show_snackbar', {
              text: 'Cambios guardados correctamente',
              color: 'success',
            }) 
          }

        }

        this.$store.state.loading = false
      }
    },
    async actualizar_datos () {
      this.vendedor_original = Object.assign({}, this.vendedor)
      this.ptosvta_original = await JSON.parse(JSON.stringify(this.ptosvta))
    },
    async get_data () {
      this.$store.state.loading = true
      await this.$store.dispatch('vendedores/get_vendedor', { vendedor: this.id })
        .then((data) => {
          this.vendedor = data
          this.vendedor.alta = moment(this.vendedor.alta).format("DD/MM/YYYY")
          if (this.vendedor.baja) {
            this.vendedor.baja = moment(this.vendedor.baja).format("DD/MM/YYYY")
          }
          this.vendedor_original = Object.assign({}, this.vendedor)
          this.vendedor_original.cuil = this.format_cuil(this.vendedor_original.cuil)
          this.load_component = true
          this.existe = true
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async get_cat_doc () {
      this.$store.state.loading = true
      await this.$store.dispatch('vendedores/get_cat_vendedor')
        .then((data) => {
          this.categorias = data.categorias
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      await this.$store.dispatch('get_tipos_doc')
        .then((data) => {
          this.tipos_doc = data.tipos_doc
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async find_localidad () {
      if (this.editar && this.$refs.codp.validate()) {
        this.localidades = []
        this.$store.state.loading = true
        await this.$store.dispatch('get_loc_x_cp', { cp: this.vendedor.cp })
          .then((data) => {
            this.localidades = data.localidades
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
        this.$store.state.loading = false

        if (this.localidades.length === 0) {
          this.$store.dispatch('show_snackbar', {
            text: 'Código postal inexistente',
            color: 'error',
          })
          this.vendedor.localidad = null
          this.vendedor.cod_loc = null
          return
        }
        if (this.localidades.length > 1) {
          this.dialog = true
        } else {
          this.vendedor.localidad = this.localidades[0].nombre
          this.vendedor.cod_loc = this.localidades[0].id
        }
        this.$refs.emailinp.focus()
      }
    },
    set_baja () {
      if (!this.vendedor_original.inhabilitado) {
        this.vendedor.baja = moment(new Date()).format("DD/MM/YYYY")
      }
    },
    select_localidad (loc) {
      this.vendedor.localidad = loc.nombre
      this.vendedor.cod_loc = loc.id
      this.dialog = false
    },
    format_cuil (cuil) {
      if (cuil == null) return ''
      cuil = cuil.toString()
      const par = cuil.substring(0, 2)
      const doc = cuil.substring(2, 10)
      const uno = cuil.substring(10, 11)
      return `${par}-${doc}-${uno}`
    },
    autocomplete_dni () {
      if (this.editar) {
        this.vendedor.doc = this.vendedor.cuil.replaceAll('-', '').substr(2, 8)
      }
    },
    cerrar () {
      window.close()
    }
  }
}
</script>